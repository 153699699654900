//src/api/common.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

// 增加购物车：
export function addCartObj(obj) {
    return request({
        url: '/shopcart/carts/addCart',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询购物车列表：
export function queryCartListObj(obj) {
    return request({
        url: '/shopcart/carts/queryCartList',   // url = base url + request url
        method: 'get',
        params: obj,
    })
}
// 删除购物车：
export function deleteCartObj(obj) {
    return request({
        url: `/shopcart/carts/deleteCartByBookId/${obj.bookId}`,    // url = base url + request url
        method: 'delete',
    })
}
// 购物车购买图书：
export function buyBooksObj(obj) {
    return request({
        url: '/study/students/buyBooks',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}





