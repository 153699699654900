<template>
  <div class="case-conent Cart">
    <div class="hd">充值购买</div>
    <div class="tab-tilte">
      <li @click="toRecharge()">按需充值</li>
      <li @click="curA=1" :class="{active:acurA}">购物车</li>
    </div>
    <div class="tab-content">
      <!-- 购物车未空 -->
      <div class="cart-box" v-if="CartListData==''">
        <div class="cart01">
          <i class="el-icon-shopping-cart-1" style="color: #333"></i>
        </div>
        <div class="cart02">
          <h2>购物车空空的哦~，去看看心仪的学历案吧</h2>
          <div class="Btn">
            <el-button @click="toCourseware()" round class="cartBtn" type="primary">去添加购物车</el-button>
          </div>
        </div>
      </div>
      <!--购物车列表-->
      <div class="cartList" v-else>
        <li class="Sbooks" v-for="(item, index) in CartListData"
            :key="index">

          <div class="item-book-bg23" v-if="item.subjectId =='23'">
            <img :src="item.coverUrl" v-if="item.coverUrl !=''" alt="">
            <img v-else src="../../assets/images/iconFm2.png" alt="">
          </div>
          <div class="item-book-bg24" v-if="item.subjectId =='24'">
            <img :src="item.coverUrl" v-if="item.coverUrl !=''" alt="">
            <img v-else src="../../assets/images/iconFm2.png" alt="">
          </div>
          <div class="item-book-bg25" v-if="item.subjectId =='25'">
            <img :src="item.coverUrl" v-if="item.coverUrl !=''" alt="">
            <img v-else src="../../assets/images/iconFm2.png" alt="">
          </div>
          <div class="item-book-bg26" v-if="item.subjectId =='26'">
            <img :src="item.coverUrl" v-if="item.coverUrl !=''" alt="">
            <img v-else src="../../assets/images/iconFm2.png" alt="">
          </div>
          <div class="item-book-bg27" v-if="item.subjectId =='27'">
            <img :src="item.coverUrl" v-if="item.coverUrl !=''" alt="">
            <img v-else src="../../assets/images/iconFm2.png" alt="">
          </div>
          <div class="item-book-bg28" v-if="item.subjectId =='28'">
            <img :src="item.coverUrl" v-if="item.coverUrl !=''" alt="">
            <img v-else src="../../assets/images/iconFm2.png" alt="">
          </div>
          <div class="item-book-bg29" v-if="item.subjectId =='29'">
            <img :src="item.coverUrl" v-if="item.coverUrl !=''" alt="">
            <img v-else src="../../assets/images/iconFm2.png" alt="">
          </div>
          <div class="item-book-bg30" v-if="item.subjectId =='30'">
            <img :src="item.coverUrl" v-if="item.coverUrl !=''" alt="">
            <img v-else src="../../assets/images/iconFm2.png" alt="">
          </div>
          <div class="item-book-bg31" v-if="item.subjectId =='31'">
            <img :src="item.coverUrl" v-if="item.coverUrl !=''" alt="">
            <img v-else src="../../assets/images/iconFm2.png" alt="">
          </div>
          <div class="item-book-bg32" v-if="item.subjectId =='32'">
            <img :src="item.coverUrl" v-if="item.coverUrl !=''" alt="">
            <img v-else src="../../assets/images/iconFm2.png" alt="">
          </div>
          <div class="item-book-bg35" v-if="item.subjectId =='35'">
            <img :src="item.coverUrl" v-if="item.coverUrl !=''" alt="">
            <img v-else src="../../assets/images/iconFm2.png" alt="">
          </div>
          <div class="Eda">
            <el-tooltip
                class="item"
                effect="dark"
                :content="item.name"
                placement="top"
            >
              <el-button type="text"
                         style="padding: 0;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;color: #000">
                {{ item.name }}
              </el-button>

            </el-tooltip>
          </div>
          <div class="Edu1">
            <div class="Edu-A">
              <div class="Eca-A" v-if="item.applyAmount !=null">
                <el-tooltip class="item" effect="dark" :content="item.applyAmount+'学币'" placement="top">
                  <span>实付：<span style="color: #ff5a00">{{item.applyAmount}}</span>学币</span>
                </el-tooltip>
              </div>
            </div>
            <div class="frequency" @click="del(item)">
              <i class="el-icon-delete-solid"></i>
            </div>
          </div>
          <div class="Edu2">
            <div class="Edu-A">
              <div class="Eca-B" v-if="item.totalDiscount !=null">
                <el-tooltip class="item" effect="dark" :content="'原价:'+item.totalDiscount+'学币'" placement="top">
                  <span>价格：{{ item.totalDiscount }}学币</span>
                </el-tooltip>
              </div>
              <div class="Eca-C" v-if="item.folderSumAmount !=null">
                <span>已付：{{ item.folderSumAmount }}</span>
              </div>
            </div>
          </div>
        </li>
      </div>
      <!--购买操作-->
      <div v-if="CartListData!=''">
        <div class="buy-box" v-if="CartBuyQr==1">
          <div class="price">
            <h1>原价：<span>{{originalprice}}学币</span></h1>
            <p>应付金额：<span>{{totalSumAll |addZero}}</span>学币</p>
          </div>
          <div class="buyBtnA">
            <el-button type="primary" class="edit_btn"  @click="buyBook()">确认购买</el-button>
          </div>
        </div>
        <!--购买操作-->
        <div class="buy-boxB" v-else>
          <div class="price">
            <h1>原价：<span>{{originalprice}}学币</span></h1>
            <p>应付金额：<span>{{totalSumAll |addZero}}</span>学币</p>
          </div>
          <div class="buyBtnA">
            <h1>还需充值：<span>{{countNum}}学币</span></h1>
            <p>余额不足！余额：<span>{{StuByUserData.account}}</span>学币</p>        </div>
          <div class="buyBtnB">
            <el-button type="danger" class="edit_btn" @click="toRecharge()">去充值</el-button>
          </div>
        </div>
      </div>




    </div>
    <el-dialog
            title=""
            :visible.sync="buyCgDialog"
            :close-on-click-modal="false"
            :modal-append-to-body='false'
            class="buyDialogA1"
            width="600px"
    >
      <div class="buyDialogA1-04">
        <i class="el-icon-success"></i><span>购买成功</span>
      </div>

      <div class="buyDialogA1-05">
        <el-button @click="buyBook()" round
                   style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff"
                   type="primary">确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import {queryCartListObj, deleteCartObj,buyBooksObj} from '@/api/cart'   //
import {getStuByUserNameObj} from '@/api/caseHome'   //路径


export default {
  name: '',
  data() {
    return {
      buyBzDialog:false,
      buyCgDialog:false,
      CartBuyQr:1,
      CartBuyCz:null,
      CartListData: []
    }
  },
  created() {
    this.EducationCase = this.$route.query.EducationCase;
    this.clickFlag = this.$route.query.clickFlag;
    this.acurA = this.$route.query.acurA;
    this.stu = this.$route.query.stu;


    this.queryCartList()
  },
  components: {
  },
  filters:{
    addZero:function(data){
      return data.toFixed(2)
    }
  },
  computed:{
    ...mapState([
      'cartCount',
      'StuByUserData'
    ]),
    originalprice(){
      let originalprice = 0;
      this.CartListData.map((item) => {originalprice += item.totalPrice})
      return originalprice
    },
    totalSumAll(){
      let totalSumAll = 0;
      this.CartListData.map((item) => {totalSumAll += item.applyAmount})
      return totalSumAll
    },
    countNum:function () {
      return Number(this.totalSumAll) - Number(this.StuByUserData.account)
    }

  },
  methods: {
    // 查询个人信息
    getStuByUser() {
      getStuByUserNameObj().then((res) => { // eslint-disable-line no-unused-vars
        if (res.code == 200) {
          //this.StuByUser = res.data
          this.$store.commit('saveStuByUser', res.data)
          this.account=res.data.account
          console.log(res.data.account,'9839994')
        }
      })

    },
    orderObjA() {
      this.orderCur =0
    },
    orderObjB() {
      this.orderCur =1
    },
    orderObjC() {
      this.orderCur =2
    },
    // 跳转到图书列表
    toCourseware() {
      this.$router.push({
        path: "/mainb/courseware",
        query: {
          EducationCase: this.EducationCase,
          clickFlag: this.clickFlag,
          stu:this.stu
        }
      });
    },
    // 跳转到充值
    toRecharge() {
      this.$router.push({
        path: "/mainb/recharge",
        query: {
          EducationCase: this.EducationCase,
          stu:1,
          clickFlag: this.clickFlag
        }
      });
    },

    // 查询购物车列表
    queryCartList() {
      let params = {}
      queryCartListObj(params).then((res) => { // eslint-disable-line no-unused-vars
        this.CartListData = res.data
        console.log(res.data, '购物车的数量')
        this.$store.commit('saveCartCount', res.data.length)
        this.getStuByUser()
      })
    },
    // 删除购物车
    del(item) {
      this.$confirm('本次操作将刪除本学历案，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let obj = {
          bookId: item.bookId
        };
        deleteCartObj(obj).then(res => {
          if (res.code == 200) {
            this.queryCartList()
            console.log(res.message, 'shan')


          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 购物车购买图书
    CartBuyBooks() {
      if(this.account<this.totalSumAll) {
        this.buyBzDialog = true
      }

    },
    buyBook() {
      buyBooksObj(this.CartListData).then((res) => { // eslint-disable-line no-unused-vars
        //this.CartListData = res.data
        if (res.code==200) {
          this.$message({
            message: '恭喜你，购买成功！',
            type: 'success'
          });
          this.buyCgDialog = false
          this.getStuByUser()
          this.queryCartList()
          this.$router.replace({
            path: "/mainb/order",
            query: {
              EducationCase:this.EducationCase,
              clickFlag:this.clickFlag,
              orderCur:2
            },
            meta: {allowBack: false}
          });
          console.log(res, '购买')
        } else if(res.code==300) {
          this.CartBuyQr=2
          this.CartBuyCz=1
        }

      })
    }
  }

}
</script>
<style lang="scss" scoped>
  .buyDialogA1 {
    ::v-deep.el-dialog__header {
      padding: var(--el-dialog-padding-primary);
      font-weight: 800;
      height: 46px;
      line-height: 46px;
      box-sizing: border-box;
      padding-left: 16px;
      border-bottom: 0px solid #f2f2f2;
      padding-bottom: 0px;

    }
    .buyDialogA1-04 {
      width: 100%;
      height: 100px;
      text-align: center;
      padding-top: 20px;
      color: #000;
      ::v-deep.el-icon-success {
        color: #6eb864;
        vertical-align: middle;
        margin-right: 10px;
      }
      .el-icon-warning {
        color: #cf9236;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    .buyDialogA1-05 {
      width: 100%;
      height: 40px;
      padding-bottom: 30px;
      text-align: center;
      padding-top: 20px;
      color: #fff;
    }
  }

.tab-tilte {
  width: 96%;
  height: 42px;
  border-bottom: 1px solid #FF9100;
  margin: 10px auto;

  li {
    float: left;
    width: 25%;
    height: 41px;
    line-height: 41px;
    padding: 0px 0;
    text-align: center;
    margin-right: 10px;
    font-weight: bold;
    background-color: #FFF5E8;
    border: 1px solid #FF9100;
    cursor: pointer;
    border-radius: 6px 6px 0px 0px;
  }

  .active {
    background-color: #FF9100;
    color: #fff;
  }
}

.tab-content {
  width: 98%;
  margin: 10px auto;

  /*购物车列表*/
  .cartList {
    width: 100%;
    height: 510px;
    margin-top: 0px;
    //height: 1200px;
    box-sizing: border-box;
    padding: 0 14px;
    margin-bottom: 10px;
    display: block;
    .Sbooks {
      width: 270px;
      height: 275px;
      background: #000;
      border: 1px solid #DDDDDD;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      list-style-type: none;
      float: left;
      margin-right: 20px;
      background: rgba(255, 255, 255, 1);
      box-sizing: border-box;
      /*text-align: center;*/
      cursor: pointer;
      border: 1px solid rgba(230, 230, 230, 1);
      opacity: 0.9;
      float: left;

      .item-book-bg1 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg1.png") no-repeat;
        background-size: cover;
        img {
          width: 68px;
          height: 104px;
          margin-top: 20px;
          margin-left: 34px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg2 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg2.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg3 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg3.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg23 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg1.png") no-repeat;
        background-size: cover;

        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg24 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg2.png") no-repeat;
        background-size: cover;

        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg25 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg3.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg26 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg8.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg27 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg9.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg28 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg7.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg29 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg6.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg30 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg5.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg31 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg4.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg32 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg10.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg33 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg10.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg35 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg44.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg4 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg4.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg5 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg5.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg6 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg6.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg7 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg7.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg8 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg8.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg9 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg9.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg10 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg10.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .item-book-bg11 {
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
        background: url("../../assets/images/item-book-bg11.png") no-repeat;
        background-size: cover;
        img {
          /*width: 358px;*/
          height: 104px;
          margin-top: 20px;
          margin-left: 50px;
          float: left;
          border: 0;
        }
      }

      .Eda {
        width: 96%;
        padding: 6px 10px;
        margin: auto auto 4px auto;
        box-sizing: border-box;
        border-bottom: 1px solid #E5E5E5;
        display: flex;
      }

      .Edu1 {
        width: 96%;
        color: #999;
        margin: 0px auto;
        padding: 0px 4px;
        box-sizing: border-box;
        display: flex;

        .Edu-A {
          width: 100%;
          line-height: 29px;
          box-sizing: border-box;
          vertical-align: center;
          justify-content: flex-start;
          .Eca-A {
            width: 100%;
            overflow: hidden;
            padding-left:20px;
            box-sizing: border-box;
            text-overflow: ellipsis;
            background: url("../../assets/images/rech-ioc-01.png") no-repeat -27px -6px;
            background-size: 70px;
            white-space: nowrap;
          }
        }

        .frequency {
          width: 40px;
          justify-content: flex-end;
          background-size: 21px 18px;
          color: #FF6820;
          text-align: right;
          cursor: pointer;
          .del {
            height: 30px;
          }
        }


      }
      .Edu2 {
        width: 96%;
        margin: 0px auto;
        color: #999;
        padding: 0px 4px;
        box-sizing: border-box;
        display: flex;

        .Edu-A {
          width: 100%;
          line-height: 29px;
          box-sizing: border-box;
          vertical-align: center;
          display: flex;


          .Eca-B {
            width: 60%;
            padding-left: 20px;
            box-sizing: border-box;
            overflow: hidden;
            justify-content: flex-start;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-decoration: line-through
          }
          .Eca-C {
            width: 40%;
            padding-left: 20px;
            justify-content: flex-end;
            text-align: right;
            box-sizing: border-box;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .frequency {
          width: 40px;
          background-size: 21px 18px;
          color: #FF6820;
          text-align: right;
          cursor: pointer;
        }


      }

    }

    li:nth-child(5n+6) {
      border-right: 0;
      margin-right: 0;
      float: left;
      margin-bottom: 20px;
    }

    li:nth-child(n+5) {
      border-bottom: 0
    }

    li:hover {
      box-shadow: 0px 0px 12px 0px rgba(163, 163, 163, 0.35);
    }
  }
  /*购买操作*/
  .buy-box {
    width: 98%;
    height: 100px;
    margin: auto;
    padding: 0 20px;
    box-sizing: border-box;
    border: 1px solid #E5E5E5;
    border-radius: 6px;
    display: flex;
    .price {
      width: 30%;
      padding-top: 24px;
      justify-content: flex-start;
      h1 {
        span {
          ext-decoration: line-through
        }
      }
      p {
        span {
          font-weight: bold;
          color: #FF4D00;
        }
      }
    }
    .buyBtnA {
      width: 70%;
      padding-top: 9px;
      box-sizing: border-box;
      justify-content:flex-end;
      .edit_btn {
        padding: 29px 20px;
      }
    }
  }
  .buy-boxB {
    width: 98%;
    height: 100px;
    margin: auto;
    padding: 0 20px;
    box-sizing: border-box;
    border: 1px solid #E5E5E5;
    border-radius: 6px;
    display: flex;
    .price {
      width: 20%;
      padding-top: 24px;
      justify-content: flex-start;
      h1 {
        span {
          ext-decoration: line-through
        }
      }
      p {
        span {
          font-weight: bold;
          color: #FF4D00;
        }
      }
    }
    .buyBtnA {
      width: 30%;
      padding-top: 24px;
      justify-content: flex-start;
      h1 {
        color: #c6c6c6;
        span {
          ext-decoration: line-through
        }
      }
      p {
        span {
          font-weight: bold;
          color: #FF4D00;
        }
      }
    }
    .buyBtnB {
      width: 40%;
      padding-top: 9px;
      box-sizing: border-box;
      justify-content:flex-end;
      .edit_btn {
        padding: 29px 20px;
      }
    }
  }
}



.case-conent {
  margin: 20px auto;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 0.9;

  .hd {
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    background: #37AEFF;
    text-align: center;
    color: #fff;
    opacity: 1;
  }

  /* 购物车 样式 */
  .cart-box {
    width: 30%;
    display: flex;
    padding: 100px;
    margin: auto;

    .cart01 {
      justify-content: flex-start;
    }

    .cart02 {
      justify-content: flex-end;

      h2 {
        margin-left: 10px;
      }

      .Btn {
        display: flex;
        padding-top: 10px;
        padding-left: 10px;
        box-sizing: border-box;

        .cartBtn {
          border: 1px solid #06A8C9;
          height: 40px;
          border-radius: 50px;
          background-color: #06A8C9;
          color: #fff
        }
      }
    }

  }

}

</style>
